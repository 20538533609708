import { Link, PageProps } from "gatsby";
import * as React from "react";
import Layout from "../components/layout";

const Error404Page: React.FC<PageProps> = () => (
  <Layout>
    <div className="fallback">
      <h1 className="txt-poster">Hoppla!</h1>
      <p className="txt-heavy">
        Tut uns leid, es gibt hier keine Seite.
        <br />
        <Link to="/" className="link_underline" aria-label="Homepage" title="Startseite">
          Hier gehts zur Startseite
        </Link>
      </p>
    </div>
  </Layout>
);

export default Error404Page;
